var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "시퀀스 목록",
                tableId: "table",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                isExcelDown: false,
              },
              on: { rowClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "검색", icon: "search" },
                        on: { btnClicked: _vm.getDeptList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "신규", icon: "add" },
                                on: { btnClicked: _vm.addDept },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.saveable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveDept,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.deleteable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.deleteUrl,
                                  isSubmit: true,
                                  param: _vm.data,
                                  mappingType: "DELETE",
                                  label: "삭제",
                                  icon: "remove",
                                },
                                on: {
                                  beforeAction: _vm.deleteDept,
                                  btnCallback: _vm.deleteCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "시퀀스ID",
                            name: "sequenceId",
                            autofocus: "",
                          },
                          model: {
                            value: _vm.data.sequenceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sequenceId", $$v)
                            },
                            expression: "data.sequenceId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "시퀀스명",
                            name: "sequenceNm",
                          },
                          model: {
                            value: _vm.data.sequenceNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sequenceNm", $$v)
                            },
                            expression: "data.sequenceNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "현재값",
                            name: "currentVal",
                          },
                          model: {
                            value: _vm.data.currentVal,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "currentVal", $$v)
                            },
                            expression: "data.currentVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "prefix 단어",
                            name: "prefix",
                          },
                          model: {
                            value: _vm.data.prefix,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "prefix", $$v)
                            },
                            expression: "data.prefix",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "숫자 자리수",
                            type: "number",
                            name: "numLength",
                          },
                          model: {
                            value: _vm.data.numLength,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "numLength", $$v)
                            },
                            expression: "data.numLength",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "증가율",
                            type: "number",
                            name: "increaseRate",
                          },
                          model: {
                            value: _vm.data.increaseRate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "increaseRate", $$v)
                            },
                            expression: "data.increaseRate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            comboItems: _vm.useFlagItems,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "설명",
                            name: "description",
                          },
                          model: {
                            value: _vm.data.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "description", $$v)
                            },
                            expression: "data.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }